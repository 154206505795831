import axiosClient from "./apiClient.ts"

export const convertAndDownload = (fileBlob, sourceFormat, targetFormat, fileName = "digitizeObject") => {
    return () => {
        var formData = new FormData()

        formData.append("sourceFormat", sourceFormat)
        formData.append("targetFormat", targetFormat)
        formData.append("file", fileBlob, fileName)

        return axiosClient
            .post("convert", formData, { headers: { "Content-Type": "multipart/form-data" }, responseType: "blob" })
            .then(res => {
                return res.data
            })
    }
}
