import { Middleware } from "redux"
import { RootState } from "../models/RootState"

export const aisSubscriptionMiddleware: Middleware<{}, RootState> =
    ({ getState }) =>
    next =>
    action => {
        if (action.type === "AIS/setBoats") {
            const subscriptionsInfo = getState().AIS.subscriptionInfo
            const disabledSubscriptionsIds = subscriptionsInfo
                .filter(subscription => !subscription.isEnabled)
                .map(subscription => subscription.id)

            if (disabledSubscriptionsIds.includes(action.payload.subscriptionId)) {
                // in case the subscription is disabled, we don't want to update the boats
                action.payload.boats = []
            }
        }

        return next(action)
    }
