import { FCWC } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Divider, Collapse, Box, IconButton } from "@mui/material"
import useCollapsibleCardStyles from "./styles"

type Props = {
    innerPadding?: number
    maxHeight?: number | undefined
    paddingBottom?: number
    unmountOnExit?: boolean | undefined
    disabled?: boolean
    isToggled: boolean
    onToggleClick: () => void
} & (
    | {
          Component?: never
          id?: never
          title?: string
      }
    | {
          Component?: JSX.Element
          id?: string
          title?: never
      }
)

const CollapsibleCard: FCWC<Props> = ({
    children,
    Component,
    id,
    innerPadding = 2,
    maxHeight,
    paddingBottom = 2,
    title,
    unmountOnExit,
    disabled,
    isToggled,
    onToggleClick,
}) => {
    const classes = useCollapsibleCardStyles()

    const getDataTestId = () => {
        const testId = title ?? id ?? ""
        return collapsibleCardTestId + testId.toLocaleLowerCase().replaceAll(" ", "-")
    }

    const TitleComponent = !!Component ? Component : <Box width="100%">{title ?? ""}</Box>
    return (
        <div className={classes.collapsibleCard}>
            <div className={classes.collapsibleCardHeader} onClick={disabled ? undefined : onToggleClick}>
                {TitleComponent}
                <IconButton disabled={disabled}>
                    <ExpandMoreIcon
                        className={(disabled ? false : isToggled) ? classes.expandedLeft : classes.collapsed}
                        data-testid={getDataTestId()}
                    />
                </IconButton>
            </div>
            <Collapse in={disabled ? false : isToggled} unmountOnExit={unmountOnExit}>
                <Divider className={classes.border} />
                <Box maxHeight={maxHeight} padding={innerPadding} paddingBottom={paddingBottom}>
                    <div>{children}</div>
                </Box>
            </Collapse>
        </div>
    )
}

export default CollapsibleCard

const collapsibleCardTestId = "qa-collapsible-card-"
