import { FC, MouseEventHandler } from "react"
import ZoomToIcon from "@mui/icons-material/FmdGood"
import { IconButton, Switch, Tooltip } from "@mui/material"
import turfBBox from "@turf/bbox"
import { Subscription } from "model/ais/Subscription"
import { LngLatBoundsLike, useMap } from "@emblautec/react-map-gl"
import { setSubVisibility } from "reducers/ais"
import { getAISSubscriptionData } from "selectors/aisSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useStyles } from "./styles"
import { mapBoatsToGeoJson } from "./utils"

type Props = {
    sub: Subscription
    toggleCard: (subId: string) => void
}

const CardTitle: FC<Props> = ({ sub, toggleCard }) => {
    const classes = useStyles()

    const subscriptionData = useAppSelector(getAISSubscriptionData)

    const dispatch = useAppDispatch()

    const { mainMap } = useMap()

    const getDisabledConditions = () => {
        const subId = sub.id
        const dataNotLoadedYet = !subscriptionData[subId]?.boats
        const receivedEmptyData = subscriptionData[subId]?.boats.length === 0
        const subIsDisabled = !sub.isEnabled
        return { dataNotLoadedYet, receivedEmptyData, subIsDisabled }
    }

    const isSwitchDisabled = () => {
        const { dataNotLoadedYet, receivedEmptyData, subIsDisabled } = getDisabledConditions()
        return dataNotLoadedYet || receivedEmptyData || subIsDisabled
    }

    const switchDisabledReason = () => {
        const { dataNotLoadedYet, receivedEmptyData, subIsDisabled } = getDisabledConditions()

        if (subIsDisabled) return "Subscription is disabled"
        if (dataNotLoadedYet) return "Data not loaded yet"
        if (receivedEmptyData) return "No data received"

        return ""
    }

    const toggleVisibility = () => {
        dispatch(setSubVisibility({ subscriptionId: sub.id, isVisible: !isSubVisible() }))
    }

    const isSubVisible = () => {
        return subscriptionData[sub.id]?.visibility === "visible"
    }

    const zoomTo: MouseEventHandler<HTMLButtonElement> = e => {
        e.stopPropagation()

        const isVisible = isSubVisible()
        if (!isVisible) {
            dispatch(setSubVisibility({ subscriptionId: sub.id, isVisible: true }))
        }

        const boxCoordinates = turfBBox(mapBoatsToGeoJson(subscriptionData[sub.id].boats))

        const bbox: LngLatBoundsLike = [
            [boxCoordinates[0], boxCoordinates[1]],
            [boxCoordinates[2], boxCoordinates[3]],
        ]

        mainMap?.fitBounds(bbox, { padding: { bottom: 45, left: 45, right: 45, top: 45 } })
    }

    let switchDisabled = isSwitchDisabled()
    return (
        <div className={classes.cardTitleContent}>
            <Tooltip arrow title={switchDisabledReason()}>
                {/* wrap switch in span to allow tooltip to be shown despite switch being disabled */}
                <span>
                    <Switch
                        checked={isSubVisible() && sub.isEnabled}
                        disabled={switchDisabled}
                        onChange={toggleVisibility}
                        onClick={e => e.stopPropagation()}
                    />
                </span>
            </Tooltip>
            <div className={classes.cardTitleText} onClick={() => toggleCard(sub.id)}>
                {sub.name}
            </div>
            <Tooltip title={switchDisabled ? "" : "Zoom To"}>
                <span>
                    <IconButton disabled={switchDisabled} onClick={zoomTo}>
                        <ZoomToIcon fontSize="medium" />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    )
}

export default CardTitle
